<!-- Page banner Area -->
<div class="page-banner bg-1" style="background-image: url('assets/img/sr/service.jpeg') !important">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Contact With Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Conatct Info -->
<div class="pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class='bx bxs-phone'></i>
                    <h4>Contact Number</h4>
                    <p> <a href="tel:{{restService.contactDetails.contact}}">{{restService.contactDetails.contact}}</a>
                    </p>

                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class='bx bxs-location-plus'></i>
                    <h4>Our Location</h4>

                    <!-- <p>
                        GG Energies Ltd.<br>
                        Unit 45 Annex Harmondsworth Lane West Drayton UB7 0LQ
                    </p> -->

                    <p [innerHTML]="restService.contactDetails.location">

                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-info">
                    <i class='bx bxs-envelope'></i>
                    <h4>Email Address</h4>
                    <p><a href="mailto:{{restService.contactDetails.email}}"> {{restService.contactDetails.email}} </a>
                    </p>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Conatct Info -->

<!-- Contact Area -->
<div class="contact-form-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <!-- <h4 (click)="checkSession('setSession')">Set Session</h4>
            <h4 (click)="checkSession('destroySession')">Destroy Session</h4>
            <h4 (click)="checkSession('checkSession')">Check Session</h4> -->
        </div>

        <div class="contact-form">
            <div *ngIf="alertView" class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>Dear Visitor!</strong> {{alertMsg}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" required placeholder="Your name"
                                formControlName="name">
                        </div>
                        <div *ngIf="f['name'].touched && f['name'].invalid" class="alert alert-danger">
                            <div *ngIf="f['name'].errors && f['name'].errors['required']">Name field
                                is required.</div>
                            <div *ngIf="f['name'].errors && f['name'].errors['minlength']">Name
                                field should be of atleast 3
                                character.</div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="email" class="form-control" required placeholder="Your email address"
                                formControlName="email">
                        </div>
                        <div *ngIf="f['email'].touched && f['email'].invalid" class="alert alert-danger">
                            <div *ngIf="f['email'].errors && f['email'].errors['required']">Field is
                                required.</div>
                            <div *ngIf="f['email'].errors && f['email'].errors['email']">Must be a
                                valid email address.</div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" required placeholder="Your Subject"
                                formControlName="subject">
                        </div>
                        <div *ngIf="f['subject'].touched && f['subject'].invalid" class="alert alert-danger">
                            <div *ngIf="f['subject'].errors && f['subject'].errors['required']">Subject field
                                is required.</div>

                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" required placeholder="Your phone number"
                                formControlName="number">
                        </div>
                        <div *ngIf="f['number'].touched && f['number'].invalid" class="alert alert-danger">
                            <div *ngIf="f['number'].errors && f['number'].errors['required']">Contact
                                is required.</div>

                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea class="form-control" cols="30" rows="6" required
                                placeholder="Write your message..." formControlName="body"></textarea>
                        </div>
                        <div *ngIf="f['body'].touched && f['body'].invalid" class="alert alert-danger">
                            <div *ngIf="f['body'].errors && f['body'].errors['required']">Message field
                                is required.</div>

                        </div>
                    </div>


                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn-one">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Contact Area -->