import { Component, OnInit, inject, ViewChild } from '@angular/core';
import {
    FormBuilder,
    Validators,
    FormsModule,
    ReactiveFormsModule,
    FormGroup,
    FormControl,
    FormArray,
} from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ServiceService } from 'src/app/rest/service.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
    zeroFormGroup: FormGroup;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    formGroup: FormGroup;
    isHidden = true;
    noErrorHidden = true;
    noError2Hidden = true;
    isNoBoilerHidden = true;
    spinnerViewHide: boolean = true;

    CB_option: string;
    CB_option_value: string;

    public apiService = { act: 'checkCriteria', searchString: '' };
    @ViewChild('stepper', { read: MatStepper }) stepper: MatStepper;
    fourthFormGroup: FormGroup;

    //firstFormGroup: FormGroup;
    submittedValue: any;
    subscription: Subscription;
    checkboxesArray = [
        {
            name: 'JSA',
            value: "Income Based Jobseeker's allowance (JSA)",
        },
        {
            name: 'ESA',
            value: 'Income Related Employment & Support Allowance (ESA)',
        },
        {
            name: 'IS',
            value: 'Income Support (IS)',
        },
        {
            name: 'PGC',
            value: 'Pension Guarantee Credit',
        },
        {
            name: 'WTC',
            value: 'Working Tax Credit (WTC)',
        },

        {
            name: 'CTC',
            value: 'Child Tax Credit (CTC)',
        },
        {
            name: 'UC',
            value: 'Universal Credit (UC)',
        },
        {
            name: 'HB',
            value: 'Housing Benefit (New Eligible Benefit Under ECO4)',
        },
        {
            name: 'PSC',
            value: 'Pension Savings Credit (New Eligible Benefit Under ECO4)',
        },
        {
            name: 'CB',
            value: 'Child Benefit',
        },
    ];

    constructor(
        private _formBuilder: FormBuilder,
        public restService: ServiceService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private _snackBar: MatSnackBar,
        private _router: Router
    ) {}

    ngOnInit() {
        this.isHidden = true;
        this.noErrorHidden = true;
        this.noError2Hidden = true;
        this.isNoBoilerHidden = true;
        this.spinnerViewHide = true;
        this.CB_option = '';
        this.CB_option_value = '';
        window.scrollTo(0, 0);

        this.zeroFormGroup = this._formBuilder.group({
            ownership: ['', [Validators.required]],
        });

        this.secondFormGroup = this._formBuilder.group({
            boilerinstalled2005: ['', [Validators.required]],
            boilerinstalled2022: [''],
        });
        this.formGroup = this._formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            telephone: ['', [Validators.required]],
            address: ['', [Validators.required]],
            postal: ['', [Validators.required]],
            message: ['', [Validators.required]],
        });

        //firstFormGRoup-start

        this.initForm();

        //firstFormGRoup-end
    }

    onChangeCB(event, option: any) {
        //if the CB is checked, show or hide CB options
        if (event.checked && option == 'Child Benefit') {
            //CB is checked, show CB options window
            this.isHidden = false;
        } else if (!event.checked && option == 'Child Benefit') {
            this.isHidden = true;
            this.CB_option = '';
            this.CB_option_value = '';
        }

        //if CB child Radio is changed
        if (option == 'CB_option') {
            //show CB Options
            this.CB_option = event.value;
        } else if (option == 'CB_option_value') {
            //show CB options Value
            this.CB_option_value = event.value;
        }

        //console.log('The event option = ', option);
        //console.log('The event checked/value = ', event.checked, event.value);
    }

    onChangeBB(event) {
        console.log(event.value);
        this.noErrorHidden = event.value == 'NO' ? false : true;
        this.isNoBoilerHidden =
            event.value == 'No Boiler and Radiators' ? false : true;
    }
    onChangeBB2(event) {
        console.log(event.value);
        this.noError2Hidden = event.value == 'NO' ? false : true;
    }
    submitForm() {
        if (
            this.zeroFormGroup.valid &&
            this.firstFormGroup.valid &&
            this.secondFormGroup.valid &&
            this.formGroup.valid
        ) {
            // if CB_option & CB_option_value are checked & Forms are valid, handle the submission
            let benefitsFormArray = this.firstFormGroup.get(
                'benefits'
            ) as FormArray;
            if (this.CB_option) {
                benefitsFormArray.push(
                    this._formBuilder.group({
                        checked: true,
                        name: 'CB_option',
                        value: this.CB_option,
                    })
                );
            }
            if (this.CB_option_value) {
                benefitsFormArray.push(
                    this._formBuilder.group({
                        checked: true,
                        name: 'CB_option_value',
                        value: this.CB_option_value,
                    })
                );
            }
            // console.log(this.zeroFormGroup.value);
            // console.log(this.firstFormGroup.value.benefits);
            // console.log(this.secondFormGroup.value);
            // console.log(this.formGroup.value);
            const payload = {
                data1: this.zeroFormGroup.value,
                data2: this.firstFormGroup.value.benefits,
                data3: this.secondFormGroup.value,
                data4: this.formGroup.value,
            };
            //enable the spinner view
            this.spinnerViewHide = false;

            //console.log(payload);
            this.recaptchaV3Service
                .execute('importantAction')
                .subscribe((token: string) => {
                    //this is a promise, when all the data has been pushed, the tokenCatcher will be populated
                    if (`Token [${token}] generated`) {
                        //patch token into inquiry form
                        payload['token'] = token;
                        payload['act'] = 'checkCriteria';

                        //console.log(payload);
                        // call http request to post form
                        this.executeInquiryForm(payload);
                    } else {
                        //Invalid form and token not generated, try again
                    }
                });
        } else {
            // Form is invalid, display error messages or perform other actions
            console.log('Form is invalid');
        }
    }
    executeInquiryForm(payload) {
        this.restService.saveFormData(payload).subscribe((response) => {
            if (response.status) {
                //disable the spinner view
                this.spinnerViewHide = true;

                //show alert with message
                // this._snackBar.open(response.msg, 'Close', {
                //     duration: 5000,
                // });

                //reset the form to step 1 and init form
                //this.ngOnInit();
                //this.stepper.reset();

                //route the form to success/ thank you page
                this._router.navigateByUrl('/success');
            } else {
                this._snackBar.open(response.msg, 'Close', {
                    duration: 2000,
                });
            }
        });
    }
    initForm() {
        //This initForm method will reset firstFormGroup back to its initial state with all checkboxes unchecked.
        this.firstFormGroup = this._formBuilder.group({
            benefits: this._formBuilder.array(
                this.checkboxesArray.map((checkbox) =>
                    this._formBuilder.group({
                        checked: false,
                        name: checkbox.name,
                        value: checkbox.value,
                    })
                )
            ),
        });
    }
}
