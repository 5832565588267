import { Component, OnInit, NgZone, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ServiceService } from 'src/app/rest/service.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    alertView: boolean = false;
    alertMsg: any = '';
    contactForm = new FormGroup({
        act: new FormControl('saveinquiry'),
        token: new FormControl(''),
        name: new FormControl('', [
            Validators.required,
            Validators.minLength(3),
        ]),
        email: new FormControl('', [Validators.required, Validators.email]),
        number: new FormControl('', [Validators.required]),
        subject: new FormControl('', [Validators.required]),
        body: new FormControl('', Validators.required),
    });

    public apiService = { act: 'checkSession', searchString: '' };

    constructor(
        private recaptchaV3Service: ReCaptchaV3Service,
        public restService: ServiceService,
        private zone: NgZone
    ) {}

    ngOnInit(): void {}

    //function to fetch company's projects
    executeInquiryForm() {
        this.restService
            .saveFormData(this.contactForm.value)
            .subscribe((response) => {
                this.zone.run(() => {
                    if (response.status) {
                        //update companies data with result

                        this.contactForm.reset();
                        this.alertMsg = response.msg;
                        this.alertView = true;
                        //this.openSnackBar(response.msg,'Close');
                        console.log(
                            'Newly fetched Inquiry data Response is:',
                            response
                        );
                    } else {
                        this.alertMsg = response.msg;
                        this.alertView = true;
                    }
                });
            });
    }

    checkSession(searchString: any) {
        this.apiService.searchString = searchString;
        this.restService.checkSession(this.apiService).subscribe((response) => {
            this.zone.run(() => {
                console.log('Session Response is:', response);
            });
        });
    }

    get f() {
        return this.contactForm.controls;
    }

    submit() {
        if (this.contactForm.valid) {
            console.log(this.contactForm.value);
            //recaptcha call
            this.recaptchaV3Service
                .execute('importantAction')
                .subscribe((token: string) => {
                    //this is a promise, when all the data has been pushed, the tokenCatcher will be populated
                    if (`Token [${token}] generated`) {
                        //patch token into inquiry form
                        this.contactForm.patchValue({
                            token: token,
                        });

                        console.log(
                            'Form after token is ready',
                            this.contactForm.value
                        );
                        // call http request to post form
                        this.executeInquiryForm();
                    } else {
                        //Invalid form and token not generated, try again
                    }
                });
        } else {
        }
    }
}
