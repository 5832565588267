<!-- Coming Soon Area -->
<div class="coming-soon-area mt-5 mb-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">

                <div class="coming-soon-contant text-justify-center">
                    <img src="assets/img/logo.png" alt="GO GREEN ENERGIES ">
                    <h1 class="text-muted">Thank You!</h1>
                    <h4>For Submitting Your Free Boiler Scheme Eligibility Check</h4>
                    <p class="mt-2 small-text-justify">
                        Your submission has been received, and our team will now review the information you
                        provided to determine your eligibility for the free boiler scheme. Please allow us sometime to
                        carefully assess your application. Our goal is to ensure that as many eligible households as
                        possible can be benefit from this program.
                    </p>

                    <div class="row">
                        <div class="col">
                            <button class="mr-3" mat-raised-button color="success" routerLink="/">Go Home</button>
                            <button class="ml-3" mat-raised-button color="accent" routerLink="/criteria">Check
                                Eligibility</button>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</div>

<!-- End Coming Soon Area -->