import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
} from '@angular/router';
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common';
import { filter } from 'rxjs/operators';
import { ServiceService } from './rest/service.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit, AfterViewInit {
    location: any;
    routerSubscription: any;
    token: string | undefined;

    constructor(private router: Router, private _service: ServiceService) {
        this.token = undefined;
    }
    ngAfterViewInit(): void {
        //this.recallJsFuntions();
    }

    ngOnInit() {
        console.log('hasCalled', this._service.hasRunContact);

        this.getBanners();
        this.getServices();
        //this.getFAQs();
        this.getWebConfigurations();
        this.recallJsFuntions();
    }

    getWebConfigurations() {
        if (!this._service.hasRunContact) {
            console.log('called from AppComponent');
            this._service
                .getWebConfigurations('blocks/contactconfigurations')
                .subscribe((response) => {
                    console.log('Server Response webconfig:', response);
                });
        }
    }
    getBanners() {
        if (!this._service.hasRunBanners) {
            this._service
                .getBannerSlides('banners/getBanner')
                .subscribe((response) => {
                    console.log('Server Response Banners:', response);
                    console.log('Calling getBanners JS include scripts');
                    $.getScript('../assets/js/custom.js');
                });
        }
    }
    getServices() {
        if (!this._service.hasRunService) {
            this._service
                .getServicesData('services/getServices')
                .subscribe((response) => {
                    //console.log('Server Response getServices:', response);
                    //console.log('Calling getServices JS include scripts');
                    $.getScript('../assets/js/custom.js');
                });
        }
    }
    getFAQs() {
        if (!this._service.hasRunFAQ) {
            this._service.getFAQData('faqs/getFAQ').subscribe((response) => {
                //console.log('Server Response getServices:', response);
                //console.log('Calling getServices JS include scripts');
                $.getScript('../assets/js/custom.js');
            });
        }
    }

    public send(form: NgForm): void {
        if (form.invalid) {
            for (const control of Object.keys(form.controls)) {
                form.controls[control].markAsTouched();
            }
            return;
        }

        console.debug(`Token [${this.token}] generated`);
    }

    recallJsFuntions() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                console.log('Calling JS include scripts');
                $.getScript('../assets/js/custom.js');

                $('.preloader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}
