<!-- Page banner Area -->
<div class="page-banner bg-2" style="background-image: url('assets/img/sr/service.jpeg') !important">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>FAQ</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Star Faq Area -->
<div class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>FAQ</span>
            <h2>Frequently Asked Questions</h2>
        </div>

        <div class="faq-contant">
            <div class="row ">
                <div class="col-lg-6 col-md-12">

                    <mat-accordion class="align-items-start">
                        <mat-expansion-panel *ngFor="let faq of faqsData; let i = index" [expanded]="i === 0">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="text-info">
                                    <mat-icon class="mr-3">question_answer</mat-icon>
                                    {{faq.title}}
                                </mat-panel-title>

                            </mat-expansion-panel-header>

                            <div class="kam text-justify" [innerHTML]="faq.descrip"></div>

                        </mat-expansion-panel>


                    </mat-accordion>

                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="faq-image">
                        <img width="100%" src="assets/img/sr/faq.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<!-- End Faq Area -->