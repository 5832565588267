import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/rest/service.service';

@Component({
    selector: 'app-services-details',
    templateUrl: './services-details.component.html',
    styleUrls: ['./services-details.component.scss'],
})
export class ServicesDetailsComponent implements OnInit {
    serviceData: any;
    serviceID: any;
    serviceDetail: any;
    url: string;
    constructor(
        private restService: ServiceService,
        private _activeRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.url = this.restService.imgUrlLocal;
        this._activeRoute.paramMap.subscribe((param) => {
            this.serviceID = param.get('id');
            //this.serviceData = this.restService.serviceList;

            //console.log(this.serviceList);
            this.getServicePageData();
        });
    }
    getServicePageData() {
        this.restService
            .getServicesData('services/getServices')
            .subscribe((response) => {
                this.serviceData = response.services;
                if (this.serviceData.length > 0) {
                    for (const session of this.serviceData) {
                        //console.log(session)
                        if (session.id == this.serviceID) {
                            this.serviceDetail = session;
                        }
                    }
                }
                console.log(
                    'Data received at Services Page = ',
                    this.serviceData,
                    this.serviceDetail
                );
            });
    }
}
