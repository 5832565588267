import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ServiceService {
    //endpointFront = 'http://localhost:8000/api/';
    endpointFront = 'https://flux.ggenergies.co.uk/public/api/';
    endpointForms = 'https://ggenergies.co.uk/apiGG/';
    //imgUrlLocal = 'http://localhost:8000/images';
    imgUrlLocal = 'https://flux.ggenergies.co.uk/public/images';

    hasRunContact = false;
    hasRunBanners = false;
    hasRunService = false;
    hasRunBlocks = false;
    hasRunFAQ = false;

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    serviceList = [];
    serviceData = [];
    blocksData = [];
    brandsData = [];
    FAQData = [];

    contactDetails = {
        contact: '',
        location: '',
        email: '',
        twitter: '',
        facebook: '',
        footer: '',
    };

    constructor(private httpClient: HttpClient) {}

    getWebConfigurations(param): Observable<any> {
        if (!this.hasRunContact) {
            this.hasRunContact = true;
            return this.httpClient.get<any>(this.endpointFront + param).pipe(
                tap((_resp) => this.setWebConfig(_resp, param)),
                catchError(this.handleError<any>(`Get user id=`))
            );
        }
    }

    setWebConfig(data: any, param) {
        //set the web config through http response
        if (param == 'blocks/contactconfigurations') {
            this.contactDetails = data.block;
        } else if (param == 'banners/getBanner') {
            this.serviceList = data.banners;
            console.log('Data banners = ', this.serviceList);
        } else if (param == 'services/getServices') {
            this.serviceData = data.services;
            this.blocksData = data.blocks;
            this.brandsData = data.brands;
            this.FAQData = data.faqs;
            //console.log('Data service/ = ', this.serviceData);
            //console.log('Data Blocks/ = ', this.blocksData);
            //console.log('Data Brands/ = ', this.brandsData);
            //console.log('Data FAQs/ = ', this.FAQData);
        }
    }
    getBannerSlides(param): Observable<any> {
        if (!this.hasRunBanners) {
            this.hasRunBanners = false;
            return this.httpClient.get<any>(this.endpointFront + param).pipe(
                tap((_resp) => this.setWebConfig(_resp, param)),
                catchError(this.handleError<any>(`Get user id=`))
            );
        }
    }

    getServicesData(param): Observable<any> {
        if (!this.hasRunService) {
            this.hasRunService = false;
            return this.httpClient.get<any>(this.endpointFront + param).pipe(
                tap((_resp) => this.setWebConfig(_resp, param)),
                catchError(this.handleError<any>(`Get user id=`))
            );
        }
    }
    getFAQData(param): Observable<any> {
        if (!this.hasRunFAQ) {
            this.hasRunFAQ = false;
            return this.httpClient.get<any>(this.endpointFront + param).pipe(
                tap((_resp) => this.setWebConfig(_resp, param)),
                catchError(this.handleError<any>(`Get user id=`))
            );
        }
    }

    //Save Inquiry Form
    saveFormData(user: any): Observable<any> {
        //user.act = 'getCompaniesData';
        //console.log('InquiryForm Data = ', user);
        return this.httpClient
            .post<any>(
                this.endpointForms,
                JSON.stringify(user),
                this.httpOptions
            )
            .pipe(catchError(this.handleError<any>('Error occured')));
    }

    checkSession(user: any): Observable<any> {
        return this.httpClient
            .post<any>(
                this.endpointForms,
                JSON.stringify(user),
                this.httpOptions
            )
            .pipe(catchError(this.handleError<any>('Error occured')));
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
