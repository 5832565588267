import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/rest/service.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
    serviceData: any[];
    blocksData: any;
    brandsData: any[];
    url: string;

    constructor(private restService: ServiceService) {
        this.url = this.restService.imgUrlLocal;
    }

    ngOnInit(): void {
        this.getServicePageData();
    }
    getServicePageData() {
        this.restService
            .getServicesData('services/getServices')
            .subscribe((response) => {
                this.brandsData = response.brands;
                this.blocksData = response.blocks;
                console.log('brands', this.brandsData);
            });
    }
}
