<!-- Page banner Area -->
<!-- <div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>QUALIFYING CRITERIA</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Apply Now</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Page banner Area -->

<!-- Start Sign up Area -->
<div class="sign-up-area ptb-100 mt-5">
    <div class="container contact-form">
        <div class="section-title mb-0">
            <h2>Check Eligibility!</h2>
            <div class="col-lg-12 col-md-12">
                Check if you meet the government requirements??
            </div>
        </div>

        <!-- <h2>What is your home ownership status? *</h2>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <button type="submit" class="btn btn-block default-btn-one">Home Owner?</button>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <button type="submit" class="btn btn-block default-btn-one">Private Tenant?</button>
                </div>
            </div>
        </div> -->

        <!-- <mat-spinner diameter="200"></mat-spinner> -->
        <mat-horizontal-stepper linear #stepper *ngIf="spinnerViewHide">
            <mat-step [stepControl]="zeroFormGroup">
                <form [formGroup]="zeroFormGroup" #ngForm="ngForm">
                    <ng-template matStepLabel>House Ownership?</ng-template>

                    <section class="example-section" [formGroup]="zeroFormGroup">
                        <h6>What is your home ownership status? *</h6>

                        <div class="childCB mt-2">
                            <mat-radio-group [ngClass]="{
                                    invalid:
                                        zeroFormGroup.get('ownership').invalid
                                }" class="example-button-row" formControlName="ownership">
                                <mat-radio-button class="example-button-row" value="home">
                                    Home Owner?
                                </mat-radio-button>
                                <mat-radio-button class="example-button-row" value="private">
                                    Private Tenant?
                                </mat-radio-button>
                            </mat-radio-group>
                            <br />
                            <span *ngIf="ngForm.submitted && !zeroFormGroup.valid" class="small-font"
                                [ngClass]="{ invalid: ngForm.submitted }">
                                * (Please select alteast one option)
                            </span>
                        </div>
                    </section>

                    <!-- <section class="example-section" [formGroup]="zeroFormGroup">
                        <h4>You choose:</h4>
                        {{ zeroFormGroup.value | json }}
                    </section> -->
                    <hr />
                    <div class="example-button-row">
                        <button mat-button mat-raised-button matStepperNext color="primary">
                            Next
                        </button>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="firstFormGroup">
                <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Benefits Currently Received</ng-template>

                    <section class="example-section mt-2" [formGroup]="firstFormGroup">
                        <h6>
                            Please select all the benefits currently received
                            within the home: *
                        </h6>
                        <div class="row" formArrayName="benefits">
                            <ng-container *ngFor="
                                    let checkbox of firstFormGroup.get(
                                        'benefits'
                                    ).controls;
                                    let i = index" [formGroupName]="i">
                                <div class="col-lg-6 col-md-12">
                                    <mat-checkbox formControlName="checked" (change)="
                                            onChangeCB($event, checkbox.value.value)
                                        ">
                                        {{ checkbox.value.value }} ({{ i }})
                                    </mat-checkbox>

                                    <div class="childCB mt-0" *ngIf="checkbox.value.name == 'CB'">
                                        <mat-radio-group class="example-button-row" [hidden]="isHidden" (change)="
                                                onChangeCB($event, 'CB_option')
                                            ">
                                            <h6>
                                                Number of Children or Qualifying
                                                Young Persons *
                                            </h6>

                                            <mat-radio-button class="example-button-row" value="Single Claimant">
                                                Single Claimant
                                            </mat-radio-button>
                                            <mat-radio-button class="example-button-row" value="Multiple Claimant">
                                                Multiple Claimant
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <ng-template [ngIf]="
                                            CB_option === 'Single Claimant' &&
                                            checkbox.value.name == 'CB'" class="childCB mt-2">
                                        <div class="childCB mt-2">
                                            <h6>
                                                Select Income for Qualifying
                                            </h6>
                                            <mat-radio-group (change)="
                                                    onChangeCB($event,'CB_option_value')" class="example-button-row">
                                                <mat-radio-button class="example-radio-button"
                                                    value="1 Child - £19,900">
                                                    1 Child - £19,900
                                                </mat-radio-button>
                                                <mat-radio-button class="example-radio-button"
                                                    value="1 Child - £19,900">
                                                    2 Child - £24,800
                                                </mat-radio-button>
                                                <mat-radio-button class="example-radio-button"
                                                    value="3 Child - £29,600">
                                                    3 Child - £29,600
                                                </mat-radio-button>
                                                <mat-radio-button class="example-radio-button"
                                                    value="4+ Child - £34,500">
                                                    4+ Child - £34,500
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </ng-template>

                                    <ng-template [ngIf]="
                                            CB_option === 'Multiple Claimant' &&
                                            checkbox.value.name == 'CB'
                                        ">
                                        <div class="childCB mt-2">
                                            <h6>
                                                Select Income for Qualifying
                                            </h6>
                                            <mat-radio-group (change)="
                                                    onChangeCB(
                                                        $event,
                                                        'CB_option_value'
                                                    )
                                                " class="example-button-row">
                                                <mat-radio-button class="example-radio-button"
                                                    value="1 Child - £27,500">
                                                    1 Child - £27,500
                                                </mat-radio-button>
                                                <mat-radio-button class="example-radio-button"
                                                    value="2 Child - £32,300">
                                                    2 Child - £32,300
                                                </mat-radio-button>
                                                <mat-radio-button class="example-radio-button"
                                                    value="3 Child - £37,200">
                                                    3 Child - £37,200
                                                </mat-radio-button>
                                                <mat-radio-button class="example-radio-button"
                                                    value="4+ Child - £42,000">
                                                    4+ Child - £42,000
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </div>
                    </section>

                    <!-- <section class="example-section" [formGroup]="firstFormGroup">
                        <h4>You chose:</h4>
                        {{ firstFormGroup.value | json }}
                    </section> -->
                    <hr />
                    <div class="example-button-row">
                        <button mat-button mat-raised-button matStepperPrevious>
                            Back
                        </button>
                        <button mat-button mat-raised-button matStepperNext color="primary">
                            Next
                        </button>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="secondFormGroup">
                <form [formGroup]="secondFormGroup" #ngForm="ngForm">
                    <ng-template matStepLabel>Boiler Installed?</ng-template>

                    <section class="example-section mt-2" [formGroup]="secondFormGroup">
                        <h6>Was your boiler installed before april 2005? *</h6>
                        <div class="row">
                            <div class="childCB mt-2">
                                <mat-radio-group (change)="onChangeBB($event)" [ngClass]="{
                                        invalid: secondFormGroup.get(
                                            'boilerinstalled2005'
                                        ).invalid
                                    }" class="example-button-row" formControlName="boilerinstalled2005">
                                    <mat-radio-button class="example-button-row" value="YES">
                                        Yes?
                                    </mat-radio-button>
                                    <mat-radio-button class="example-button-row" value="NO">
                                        No?
                                    </mat-radio-button>
                                    <mat-radio-button class="example-button-row" value="No Boiler and Radiators">
                                        No Boiler and Radiators
                                    </mat-radio-button>
                                </mat-radio-group>

                                <br />
                                <span *ngIf="
                                        ngForm.submitted &&
                                        !secondFormGroup.valid
                                    " class="small-font" [ngClass]="{ invalid: ngForm.submitted }">
                                    * (Please select alteast one option)
                                </span>
                                <br />
                                <!-- <mat-card class="invalid" [hidden]="noErrorHidden">
                                    <mat-card-content color="warning">
                                        Funding for boiler grants has been
                                        reduced in recent years and
                                        unfortunately it doesn’t look like you
                                        qualify. We recommend contacting your
                                        energy provider, or your local council,
                                        or Simple Energy Advice on +44
                                        7823952761
                                    </mat-card-content>
                                </mat-card> -->

                                <div class="childCB mt-1" [hidden]="isNoBoilerHidden">
                                    <h6>
                                        Gas meter installed before march 2022? *
                                    </h6>
                                    <mat-radio-group (change)="onChangeBB2($event)" [ngClass]="{
                                            invalid: secondFormGroup.get(
                                                'boilerinstalled2022'
                                            ).invalid
                                        }" class="example-button-row" formControlName="boilerinstalled2022">
                                        <mat-radio-button class="example-button-row" value="YES">
                                            Yes?
                                        </mat-radio-button>
                                        <mat-radio-button class="example-button-row" value="NO">
                                            No?
                                        </mat-radio-button>

                                        <!-- <mat-card class="invalid" [hidden]="noError2Hidden">
                                            <mat-card-content color="warning">
                                                Funding for boiler grants has
                                                been reduced in recent years and
                                                unfortunately it doesn’t look
                                                like you qualify. We recommend
                                                contacting your energy provider,
                                                or your local council, or Simple
                                                Energy Advice on +44 7823952761
                                            </mat-card-content>
                                        </mat-card> -->
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- <section class="example-section" [formGroup]="secondFormGroup">
                        <h4>You chose:</h4>
                        {{ secondFormGroup.value | json }}
                    </section> -->
                    <hr />
                    <div class="example-button-row">
                        <button mat-button mat-raised-button matStepperPrevious>
                            Back
                        </button>
                        <button mat-button mat-raised-button matStepperNext color="primary">
                            Next
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Inquiry Form</ng-template>
                <!-- <div class="col-lg-5 col-md-12">You are now done.</div> -->
                <form [formGroup]="formGroup" (ngSubmit)="submitForm()">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <mat-form-field>
                                <input matInput type="text" id="name" formControlName="name" placeholder="Name" />
                                <mat-error *ngIf="
                                        formGroup.get('name').invalid &&
                                        formGroup.get('name').touched
                                    ">
                                    <span *ngIf="
                                            formGroup.get('name').errors
                                                .required
                                        ">Name is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <mat-form-field>
                                <input matInput type="email" id="email" formControlName="email" placeholder="Email" />
                                <mat-error *ngIf="
                                        formGroup.get('email').invalid &&
                                        formGroup.get('email').touched
                                    ">
                                    <span *ngIf="
                                            formGroup.get('email').errors
                                                .required
                                        ">Email is required</span>
                                    <span *ngIf="
                                            formGroup.get('email').errors.email
                                        ">Invalid email format</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-12">
                            <mat-form-field>
                                <input matInput type="tel" id="telephone" formControlName="telephone"
                                    placeholder="Telephone" />
                                <mat-error *ngIf="
                                        formGroup.get('telephone').invalid &&
                                        formGroup.get('telephone').touched
                                    ">
                                    <span *ngIf="
                                            formGroup.get('telephone').errors
                                                .required
                                        ">Telephone is required</span>
                                    <span *ngIf="
                                            formGroup.get('telephone').errors
                                                .pattern
                                        ">Invalid telephone format</span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-3 col-md-12">
                            <mat-form-field>
                                <input matInput type="text" id="postal" formControlName="postal" placeholder="Postal" />
                                <mat-error *ngIf="
                                        formGroup.get('postal').invalid &&
                                        formGroup.get('postal').touched
                                    ">
                                    <span *ngIf="
                                            formGroup.get('postal').errors
                                                .required
                                        ">Postal is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <mat-form-field>
                                <input matInput type="text" id="address" formControlName="address"
                                    placeholder="Address" />
                                <mat-error *ngIf="
                                        formGroup.get('address').invalid &&
                                        formGroup.get('address').touched
                                    ">
                                    <span *ngIf="
                                            formGroup.get('address').errors
                                                .required
                                        ">Address is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <mat-form-field>
                                <textarea rows="5" cols="40" matInput id="message" formControlName="message"
                                    placeholder="Message"></textarea>
                                <mat-error *ngIf="
                                        formGroup.get('message').invalid &&
                                        formGroup.get('message').touched
                                    ">
                                    <span *ngIf="
                                            formGroup.get('message').errors
                                                .required
                                        ">Message is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <hr />
                    <div class="example-button-row mt-2">
                        <button mat-button mat-raised-button matStepperPrevious>
                            Back
                        </button>
                        <button mat-button mat-raised-button (click)="stepper.reset()" color="warn">
                            Reset
                        </button>
                        <button mat-raised-button color="primary" type="submit">
                            Submit
                        </button>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>

        <div class="centers">
            <mat-spinner [hidden]="spinnerViewHide"></mat-spinner>
        </div>
    </div>
</div>
<!-- End Sign up  Area -->

<!-- <form [formGroup]="firstFormGroup">
    <ng-container *ngFor="let checkbox of checkboxesArray; let i = index" formArrayName="checkboxes">

        <div class="col-lg-5 col-md-12">
            <mat-checkbox [formControlName]="i" (change)="onChangeCB($event)">
                {{checkbox.value}} ({{i}})
            </mat-checkbox>
        </div>
    </ng-container>
    <button (click)="submit()">Submit</button>
</form> -->

<!-- Checkbox values: {{ firstFormGroup.controls.checkboxes.value | json}}
<br />
Submitted values: {{ submittedValue | json}} -->