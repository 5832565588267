import {
    Component,
    OnInit,
    Directive,
    ElementRef,
    AfterViewInit,
} from '@angular/core';
import { ServiceService } from 'src/app/rest/service.service';

declare var $: any;

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss'],
})
export class HomeOneComponent implements OnInit {
    serviceList: any[] = [];
    url: string;
    constructor(public restService: ServiceService, private el: ElementRef) {
        this.url = this.restService.imgUrlLocal;
    }

    ngOnInit(): void {
        //this.getBanners();
    }

    // getBanners() {
    //     if (!this.restService.hasRunBanners) {
    //         this.restService
    //             .getBannerSlides('banners/getBanner')
    //             .subscribe((response) => {
    //                 // this.serviceList = response.banners;
    //                 // setTimeout(() => {
    //                 //     $('.hero-slider').owlCarousel();
    //                 // }, 1000);
    //             });
    //     }
    // }
}
