<!-- Page banner Area -->
<div class="page-banner bg-2" style="background-image: url('assets/img/sr/service.jpeg') !important">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Our Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Our Services Area -->
<div class="our-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>Safe, Faster and Relaible Insulation Services</h2>
        </div>

        <div class="row">
            <div *ngFor="let service of serviceData" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="{{url}}/services/{{service.b_image}}" alt="image">
                    <div class="service-caption">
                        <h3>{{service.title}}</h3>
                        <p>{{service.short}}</p>
                        <a routerLink="/services/services-details/{{service.id}}" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
<!-- Our End Services Area -->

<!-- Best Logistic Area -->
<ng-container *ngFor="let block of blocksData">
    <div class="best-logistic-area ptb-100" *ngIf="block.meta == 'services-insulation'">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="logistic-text">
                        <h6 [innerHTML]="block.title2"></h6>
                        <h2 [innerHTML]="block.title1"></h2>
                        <p [innerHTML]="block.descrip" class="text-justify kam"></p>
                    </div>
                    <a routerLink="/about" class="default-btn-one">Read More</a>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="logistic-image">
                        <img src="{{url}}/blocks/{{block.b_image}}" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- End Best Logistic Area -->