<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>404 Error Page</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>404 Error</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- 404 Error Area -->
<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error">
                    <img class="error-image" src="assets/img/error.png" alt="image">
                    <h2>Page Not Found</h2>
                    <div class="error-btn">
                        <a routerLink="/">Back To Home</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End 404 Error Area -->

