<!-- Hero Slider -->

<div class="hero-slider owl-carousel owl-theme" *ngIf="restService.serviceList.length > 0">

    <div *ngFor="let service of restService.serviceList" class="hero-slider-item"
        [style.backgroundImage]="'url('+url+'/banners/'+ service.b_image +')'">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content text-uppercase">
                        <span>{{service.title2}}</span>
                        <h1>{{service.title1}}</h1>
                        <p>{{service.title3}}</p>
                        <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                        <a href="{{service.link}}" class="default-btn-two">Details</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- End Hero Slider -->

<!-- Contact Area -->
<div class="container">
    <div class="contact-area mb-85">
        <div class="contact-content">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-phone'></i>
                        <h4>Contact Number</h4>
                        <p><a
                                href="tel:{{restService.contactDetails.contact}}">{{restService.contactDetails.contact}}</a>
                        </p>

                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-location-plus'></i>
                        <h4>Our Location</h4>
                        <p [innerHTML]="restService.contactDetails.location"></p>

                    </div>
                </div>



                <div class="col-lg-4 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-envelope'></i>
                        <h4>Our Email</h4>
                        <p><a
                                href="mailto:{{restService.contactDetails.email}}">{{restService.contactDetails.email}}</a>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Contact Area -->

<!-- About Area -->
<ng-container *ngFor="let block of restService.blocksData">
    <div class="about-area pb-100" *ngIf="block.meta == 'home-about'">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="about-contant">
                        <div class="section-title">
                            <span> {{block.title2}} </span>
                            <h2>{{block.title1}}</h2>
                        </div>
                        <div class="about-text text-justify">
                            <p [innerHTML]="block.descrip"></p>

                            <a routerLink="/about" class="default-btn-one btn-bs">Learn More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-12">
                    <div class="about-image">
                        <img src="{{url}}/blocks/{{block.b_image}}" alt="gogreen">
                    </div>
                </div>

                <div class="col-lg-3 col-md-12">
                    <div class="about-tabs">
                        <div class="tab-contant text-justify">
                            <h2 class="title">Our Core Value</h2>
                            <nav>
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a class="nav-link active" id="nav-vision-tab" data-toggle="tab" href="#nav-vision"
                                        role="tab" aria-controls="nav-vision" aria-selected="true">Vision</a>
                                    <a class="nav-link nav-link-two" id="nav-mission-tab" data-toggle="tab"
                                        href="#nav-mission" role="tab" aria-controls="nav-mission"
                                        aria-selected="false">Mission</a>
                                    <a class="nav-link nav-link-two" id="nav-value-tab" data-toggle="tab"
                                        href="#nav-value" role="tab" aria-controls="nav-value"
                                        aria-selected="false">Value</a>
                                </div>
                            </nav>

                            <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="nav-vision" role="tabpanel">
                                    <div class="vision kam" [innerHTML]="block.vision">

                                    </div>
                                </div>

                                <div class="tab-pane fade" id="nav-mission" role="tabpanel">
                                    <div class="vision kam" [innerHTML]="block.mission">

                                    </div>
                                </div>

                                <div class="tab-pane fade" id="nav-value" role="tabpanel">
                                    <div class="vision kam" [innerHTML]="block.value">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- End About Area -->

<!-- Counter Area -->
<div class="container">
    <div class="counter-area">
        <div class="counter-contant text-center">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="counter-card">
                        <i class="bx bx-badge-check"></i>
                        <h3><span class="odometer">Check your Eligibility</span></h3>
                        <!-- <p>Complete the Eligibility Check form, and our experts will get in touch with you to assist in
                            identifying the most appropriate grants and services based on your requirements and the type
                            of property you possess.</p> -->
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bx bx-calendar-check'></i>
                        <h3><span class="odometer">Arrange a survey </span></h3>
                        <!-- <p>One of our surveyors will come to your home at a convenient time to conduct a survey to
                            determine the best way to increase your home's energy efficiency.</p> -->
                    </div>
                </div>



                <div class="col-lg-4 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-group'></i>
                        <h3><span class="odometer">Book Installation</span></h3>
                        <!-- <p>After we approve the funding, we will start upgrading your home by installing heating and
                            insulation systems. We will arrange for one of our skilled engineers to install these
                            systems on a date that works best for you. This process usually takes 2-4 hours.</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Counter Area -->


<!-- Services Area -->
<div class="services-area services-two pb-100" *ngIf="restService.serviceData.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <h2>Helping you to Go Green</h2>
        </div>

        <div class="services-sliders owl-carousel owl-theme">
            <div *ngFor="let service of restService.serviceData" class="service-card">
                <div>
                    <img src="{{url}}/services/icon/{{service.icon_image}}" class="round-img" alt="{{service.title}}">
                </div>
                <h3>{{service.title}}</h3>

                <a routerLink="/services/services-details/{{service.id}}" class="default-btn-two">Read More</a>
            </div>


        </div>
    </div>
</div>

<!-- End Services Area -->

<!-- Choose Us Area -->
<ng-container *ngFor="let block of restService.blocksData">
    <div class="choose-us-two" *ngIf="block.meta == 'home-why'">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="choose-content">
                        <div class="choose-us-text">
                            <div class="section-title">
                                <span>{{ block.title2 }}</span>
                                <h4> {{ block.title1 }} </h4>
                            </div>
                        </div>
                        <div class="kam text-justify" [innerHTML]="block.descrip"></div>
                        <a routerLink="/contact" class="default-btn-one btn-bs">Contact Us</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="choose-us-image">
                        <img src="{{url}}/blocks/{{block.b_image}}" alt="gogreen">
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div *ngIf="block.meta == 'home-criteria'" class="shipmante-area pt-5 pb-5 mb-5 shipmante-area-bg choose-us-two"
        [style.backgroundImage]="'url('+url+'/blocks/'+ block.b_image +')'">


        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="choose-content">
                        <div class="choose-us-text">
                            <div class="section-title">
                                <span class="text-warning">{{ block.title2 }}</span>
                                <h1 class="text-white"> {{ block.title1 }} </h1>
                            </div>
                        </div>
                        <div class="text-light kam text-justify" [innerHTML]="block.descrip"></div>
                        <a class="default-btn-one btn-sm" routerLink="/criteria">Check Eligibility</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="choose-us-image">
                        <img src="{{url}}/blocks/{{block.b_image}}" alt="gogreen">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-10 col-md-12">

                    <div class="about-info-card">
                        <h1 class="text-white">{{ block.title1 }}</h1>
                        <h6 class="text-white text-warning">
                            {{ block.title2 }}
                        </h6>

                        <div class="text-white custom-list " [innerHTML]="block.descrip"></div>
                        <div class="text-white custom-list" [innerHTML]="block.descrip">
                            <ul>
                                <li> Income Based Jobseeker's Allowance (JSA)</li>

                                <li> Income Support (IS)</li>
                                <li> Pension Guarantee Credit</li>

                                <li> Working Tax Credit (WTC)</li>
                                <li> Child Tax Credit (CTC)</li>
                                <li> Universal Credit (UC)</li>
                                <li> Housing Benefit</li>
                                <li> Pension Savings Credit</li>
                                <li> Child Benefit</li>
                                <li> Income Related Employment & Support Allowance (ESA)
                                </li>
                            </ul>

                        </div>


                    </div>
                    <a class="default-btn-one btn-sm" routerLink="/criteria">Check Eligibility</a>

                </div>


            </div>
        </div> -->
    </div>
</ng-container>

<!-- End Choose Us Area -->

<!-- Shipmante Area -->

<!-- End Shipmante Area -->

<!-- Partner Area -->
<div class="partner-area pb-100 pt-100">
    <div class="container">
        <div class="section-title">
            <span>Partners</span>
            <h2>Companies Trusted us</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme" *ngIf="restService.brandsData.length > 0">
            <div class="partner-slider-item" *ngFor="let brand of restService.brandsData">
                <a href="#" target="_blank">
                    <img src="{{url}}/brands/{{brand.b_image}}" alt="{{brand.title}}" title="{{brand.title}}"
                        width="200">
                </a>
            </div>


        </div>
    </div>
</div>
<!-- End Partner Area -->
<hr>