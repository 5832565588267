<!-- Header Area -->
<header class="header-area">
    <div class="top-header" style="background-color: #036667;">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="left-info">
                        <li>
                            <a href="mailto:{{restService.contactDetails.email}}">
                                <i class='bx bxs-envelope'></i>
                                {{restService.contactDetails.email}}
                            </a>
                        </li>
                        <li><a href="tel:{{restService.contactDetails.contact}}">
                                <i class='bx bxs-phone-call'></i>
                                {{restService.contactDetails.contact}}
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <ul class="right-info">
                        <!-- <li class="mr-20"><a routerLink="/contact">Contact</a></li> -->
                        <!-- <li class="mr-20"><a routerLink="/">Career</a></li>
                        <li class="mr-20"><a routerLink="/">News & Media</a></li> -->
                        <li><a href="{{restService.contactDetails.facebook}} " target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li><a href="{{restService.contactDetails.twitter}}" target="_blank"><i
                                    class='bx bxl-twitter'></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="logox"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logoy"></a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav ml-auto">


                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Home</a></li>

                            <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                            <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Services</a></li>


                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Contact</a></li>

                            <!-- <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    Contact
                                </a> -->
                            <a routerLink="/criteria" class="nav-link">
                                <!-- <button type="submit" class="btn btn-success">Apply Now</button> -->
                                <button mat-raised-button color="accent">Apply Now</button>
                            </a>

                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- End Header Area -->