<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row ">
                <div class="col-lg-8 col-md-12">
                    <div class="newsletter-title text-white">
                        <h4 class="text-white">Check Your Eligibility To Avail These Grants</h4>
                        <p>Are you interested?</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <a class="btn btn-danger btn-bs" routerLink="/criteria">Check Eligibility</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
<!-- Footer Area-->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget">
                    <div class="logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </div>
                    <p [innerHTML]="restService.contactDetails.footer"></p>
                    <ul class="footer-socials">
                        <li><a href="{{restService.contactDetails.facebook}}" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li><a href="{{restService.contactDetails.twitter}}" target="_blank"><i
                                    class='bx bxl-twitter'></i></a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>Company</h3>

                    <ul class="footer-text">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/faq">FAQ’s</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>

                    </ul>
                </div>
            </div>


            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="info-list">
                        <li><i class='bx bxs-location-plus'></i>
                            {{restService.contactDetails.location}}
                        </li>
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <a href="mailto:{{restService.contactDetails.email}}">
                                {{restService.contactDetails.email}}
                            </a>
                        </li>
                        <li>
                            <i class='bx bxs-phone'></i>
                            <a href="tel:{{restService.contactDetails.contact}}">
                                {{restService.contactDetails.contact}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Footer bottom Area -->
<div class="footer-bottom">
    <div class="container">
        <p>Copyright @2023 GGENERGIES. All Rights Reserved <a routerLink="/">Go Green ENERGIES</a></p>
    </div>
</div>
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top -->