<!-- Page banner Area -->
<div class="page-banner" style="background-image: url('assets/img/sr/service.jpeg') !important">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Service Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li>{{serviceDetail.title}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Single Service Area -->
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text text-justify">
                    <div class="service-image">
                        <img src="{{url}}/services/{{serviceDetail.b_image}}" alt="image">
                    </div>
                    <h3>{{serviceDetail.title}}</h3>
                    <p>{{serviceDetail.meta}}</p>
                    <p [innerHTML]="serviceDetail.descrip"></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Our Services</h3>
                    <ul>
                        <li *ngFor="let service of serviceData">
                            <a routerLink="/services/services-details/{{service.id}}">
                                <i class='bx bx-home'></i>
                                {{service.title}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Single Service Area -->