import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/rest/service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public restService: ServiceService) { }

  ngOnInit(): void {
  }

}
