import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/rest/service.service';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
    serviceData: any[];
    blocksData: any;
    url: string;

    constructor(private restService: ServiceService) {
        this.url = this.restService.imgUrlLocal;
    }

    ngOnInit(): void {
        //this.serviceList = this.restService.serviceData;
        this.getServicePageData();
        //console.log('Data received at Services Page = ', this.serviceList);
        // this._service.serviceDatas$.subscribe((data) => {
        //     this.serviceDatas = data;
        //     console.log('Data received at Services Page = ', this.serviceDatas);
        // });
    }
    getServicePageData() {
        this.restService
            .getServicesData('services/getServices')
            .subscribe((response) => {
                this.serviceData = response.services;
                this.blocksData = response.blocks;
                console.log(
                    'Data received at Services Page = ',
                    this.serviceData
                );
            });
    }
}
