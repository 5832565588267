import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ServiceService } from 'src/app/rest/service.service';
import { MatAccordion } from '@angular/material/expansion';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
    serviceData: any[];
    blocksData: any;
    brandsData: any[];
    faqsData: any[];
    url: string;
    panelOpenState = false;
    @ViewChild(MatAccordion) accordion: MatAccordion;

    constructor(private restService: ServiceService) {
        this.url = this.restService.imgUrlLocal;
    }

    ngOnInit(): void {
        this.getFAQPageData();
    }

    getFAQPageData() {
        this.restService.getFAQData('faqs/getFAQ').subscribe((response) => {
            this.faqsData = response.faqs;
            //console.log('FAQs', this.faqsData);
        });
    }
}
