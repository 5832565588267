<!-- Page banner Area -->
<div class="page-banner bg-1" style="background-image: url('assets/img/hero-slider/gg.jpg') !important">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- About Safe Area -->
<ng-container *ngFor="let block of blocksData">
    <div class="about-text-area ptb-100" *ngIf="block.meta == 'about-intro'">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">


                    <div class="about-info-card kam">
                        <span> {{block.title2}} </span>
                        <h3> {{block.title1}} </h3>
                        <div class="kam text-justify" [innerHTML]="block.descrip"></div>

                    </div>


                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="safe-image">
                        <img src="{{url}}/blocks/{{block.b_image}}" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- End About Safe Area -->

    <!-- About Info -->
    <div class="about-info-area pb-70" *ngIf="block.meta == 'about-intro'">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="about-info-card">
                        <h3>Our Mission</h3>
                        <div class="kam text-justify" [innerHTML]="block.mission"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="about-info-card">
                        <h3>Our Vision</h3>
                        <div class="kam text-justify" [innerHTML]="block.vision"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="about-info-card">
                        <h3>Our Values</h3>
                        <div class="kam text-justify" [innerHTML]="block.value"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End About Info -->

    <!-- Digital Area -->
    <div class="digital-area ptb-100" *ngIf="block.meta == 'about-affordable'">
        <div class="container">
            <div class="digital-top-contant">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="digital-image">
                            <img src="{{url}}/blocks/{{block.b_image}}" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="digital-text">
                            <h2>{{block.title1}} <span>{{block.title2}}</span></h2>

                            <div class="kam text-justify" [innerHTML]="block.descrip"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="digital-card-contant">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="digital-card">
                            <div class="card-text">
                                <i class='bx bx-cart-alt'></i>
                                <h3><span>1998</span> - Company Started</h3>
                                <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="digital-card">
                            <div class="card-text">
                                <i class='bx bx-map-alt'></i>
                                <h3><span>2008</span> - Office worldwide</h3>
                                <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="digital-card">
                            <div class="card-text">
                                <i class='bx bxs-truck'></i>
                                <h3><span>2004</span> - Vehicles Adding</h3>
                                <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="digital-card">
                            <div class="card-text">
                                <i class='bx bx-award'></i>
                                <h3><span>2012</span> - Award Of The Year Won</h3>
                                <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <!-- End Digital Area -->
</ng-container>

<!-- Partner Area -->
<!-- <div class="partner-area pb-100 pt-100">
    <div class="container">
        <div class="section-title">
            <span>Partners</span>
            <h2>Companies Trusted us</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-slider-item" *ngFor="let brand of brandsData">
                <a href="#" target="_blank">
                    <img src="{{url}}/brands/{{brand.b_image}}" alt="{{brand.title}}" title="{{brand.title}}"
                        width="200">
                </a>
            </div>


        </div>
    </div>
</div> -->
<!-- End Partner Area -->